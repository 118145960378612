import { DefaultColorPreset, DurationInfo, ProductClient } from "Domain/report";
import { PhoenixTransversalConfiguration } from "Domain/report.phoenix";
import { PhoenixTransversalConfigurationDto } from "Domain/reportDto.phoenix";
import { isNil } from "lodash";
import { DateTime } from "luxon";
import { colorPresetMapper } from "Mappers/colorPresetMapper";
import { DurationInfoMappers } from "Mappers/durationInfoMappers";
import { toDateOnly, toJSDate } from "Utilities/date";

export function computeDuration(issueDate: Date | undefined, maturityDate: Date | undefined): DurationInfo {
	if (isNil(issueDate) || isNil(maturityDate)) {
		return {
			years: 0,
			months: 0,
			days: 0,
		};
	}
	const start = DateTime.fromJSDate(issueDate).startOf("day");
	const end = DateTime.fromJSDate(maturityDate).startOf("day");
	const res = end.diff(start, ["years", "months", "days"], { conversionAccuracy: "casual" }); // TODO : Check with thomas

	return {
		years: res.years,
		months: res.months,
		days: res.days,
	};
}

function mapEntityToDto(entity: PhoenixTransversalConfiguration): PhoenixTransversalConfigurationDto {
	const dto: PhoenixTransversalConfigurationDto = {
		productDuration: DurationInfoMappers.mapEntityToDto(entity.productDuration),
		productName: entity.productName,
		productGuarantor: entity.productGuarantor ?? "",
		productCotation: entity.productCotation,
		calculationAgent: entity.calculationAgent,
		productIssuer: entity.productIssuer,
		productCurrency: entity.productCurrency,
		minimumInvestment: entity.minimumInvestment,
		isin: entity.isin,
		mtmPublication: entity.mtmPublication,
		productLaw: entity.productLaw,
		hasMarketingPeriod: entity.hasMarketingPeriod,
		marketingStartDate:
			entity.hasMarketingPeriod && entity.marketingPeriod?.startDate
				? toDateOnly(entity.marketingPeriod?.startDate)
				: undefined,
		marketingEndDate:
			entity.hasMarketingPeriod && entity.marketingPeriod?.endDate
				? toDateOnly(entity.marketingPeriod?.endDate)
				: undefined,
		maturityDate: toDateOnly(entity.maturityDate),
		issueDate: toDateOnly(entity.issueDate),
		bidOffer: entity.bidOffer,
		colorPreset: colorPresetMapper.mapEntityToDto(entity.colorPreset),
		doubleValuation: entity.doubleValuation,

		productEligibility: entity.productEligibility,
		pictureId: entity.pictureId,
		commission: entity.commission,
		commissionType: entity.commissionType,
		issuePrice: entity.issuePrice!,
		priceRate: entity.priceRate!,
		productDenomination: entity.productDenomination!,
		ftDate: toDateOnly(entity.ftDate),
		client: entity.client,
		initialValuationDate: toDateOnly(entity.initialValuationDate),
	};

	return dto;
}

function mapDtoToEntity(dto: PhoenixTransversalConfigurationDto): PhoenixTransversalConfiguration {
	const maturityDate = toJSDate(dto.maturityDate)!;
	const issueDate = toJSDate(dto.issueDate)!;
	const entity: PhoenixTransversalConfiguration = {
		productName: dto.productName,
		productGuarantor: dto.productGuarantor,
		productCotation: dto.productCotation,
		calculationAgent: dto.calculationAgent,
		productIssuer: dto.productIssuer,
		productCurrency: dto.productCurrency,
		minimumInvestment: dto.minimumInvestment,
		isin: dto.isin,
		mtmPublication: dto.mtmPublication,
		productLaw: dto.productLaw,
		maturityDate: maturityDate,
		issueDate: issueDate,
		productDuration:
			DurationInfoMappers.mapDtoToEntity(dto.productDuration) ?? computeDuration(issueDate, maturityDate),
		bidOffer: dto.bidOffer,
		colorPreset: colorPresetMapper.mapDtoToEntity(dto.colorPreset ?? DefaultColorPreset),
		doubleValuation: dto.doubleValuation,
		productEligibility: dto.productEligibility,
		pictureId: dto.pictureId,
		commission: dto.commission,
		commissionType: dto.commissionType,
		issuePrice: dto.issuePrice,
		priceRate: dto.priceRate,
		productDenomination: dto.productDenomination,
		ftDate: dto.ftDate ? toJSDate(dto.ftDate) : undefined,
		client: dto.client as ProductClient,
		initialValuationDate: toJSDate(dto.initialValuationDate, new Date()),
		hasMarketingPeriod: dto.hasMarketingPeriod,
		marketingPeriod: dto.hasMarketingPeriod
			? {
					startDate: toJSDate(dto.marketingStartDate, new Date()),
					endDate: toJSDate(dto.marketingEndDate, new Date()),
				}
			: undefined,
	};

	return entity;
}

export const PhoenixTransversalConfigurationMapper = {
	mapDtoToEntity,
	mapEntityToDto,
};
