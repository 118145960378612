import axios, { AxiosRequestConfig, AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { isEmpty } from "lodash";
import { AthenaReportModel, AthenaUnderlyingId } from "Domain/report.athena";
import { TarnReportModel } from "Domain/report.tarn";
import { TauxFixeReportModel } from "Domain/report.tauxfixe";
import { ReportInfoItemDto, ReportStatusDto } from "Domain/reportDto";
import { AthenaReportModelDto, GetAllAvailableAthenaUnderlyingsResponseDto } from "Domain/reportDto.athena";
import { TarnReportModelDto } from "Domain/reportDto.tarn";
import { TauxFixeReportModelDto } from "Domain/reportDto.tauxfixe";
import { athenaReportModelMapper } from "Mappers/Athena/athenaReportModelMapper";
import { athenaUnderlyingIdMapper } from "Mappers/Athena/athenaUnderlyingIdMapper";
import { tarnReportModelMapper } from "Mappers/Tarn/tarnReportModelMapper";
import { tauxFixeReportModelMapper } from "Mappers/TauxFixe/tauxFixeReportModelMapper";
import { reportDocumentTypeMapper } from "Mappers/reportDocumentTypeMapper";
import { baseReportMapper } from "Mappers/reportInfoMapper";
import {
	getAllReportsEndpoint,
	getInitFromXOneIdEndpoint,
	createAthenaReportEndpoint,
	createTarnReportEndpoint,
	createTauxFixeReportEndpoint,
	getReportStatusEndpoint,
	getReportConfigurationEndpoint,
	getAthenaUnderlyingsEndpoint,
	getDeleteReportEndpoint,
	downloadReportEndpoint,
	getAllImageEndpoint,
	getImageEndpoint,
	getAllDisclaimersEndpoint,
	getAllUnderlyingsEndpoint,
	createPhoenixBearishReportEndpoint,
	createPhoenixReportEndpoint,
} from "./brochureUrlEndpoints";
import { Guid, PictureInfo, ReportDocumentType, ReportInfoItem, ReportType } from "Domain/report";
import { CANCEL } from "redux-saga";
import { stringify } from "qs";
import { DisclaimerItem, UnderlyingItem } from "state/Resources/Resources.state";
import { SGWTConnectCore } from "@sgwt/connect-core";
import { PhoenixBearishReportModel } from "Domain/report.phoenixBearish";
import { PhoenixBearishReportModelDto } from "Domain/reportDto.phoenixBearish";
import { phoenixBearishReportModelMapper } from "Mappers/PhoenixBearish/phoenixBearishReportModelMapper";
import { PhoenixReportModelDto } from "Domain/reportDto.phoenix";
import { PhoenixReportModel } from "Domain/report.phoenix";
import { phoenixReportModelMapper } from "Mappers/Phoenix/phoenixReportModelMapper";

interface GetAllUserReportResponseDto {
	reports: ReportInfoItemDto[];
}

interface GetAllPicturesResponseItemDto {
	id: string;
	name: string;
}

interface GetAllPicturesResponseDto {
	pictures: GetAllPicturesResponseItemDto[];
}

export interface InitReportFromXOneRequestParams {
	referenceId: string;
	reportType: ReportType;
}

interface GetAllDisclaimersResponseDto {
	disclaimers: DisclaimerItemDto[];
}

interface DisclaimerItemDto {
	content: string;
	description: string;
	disclaimerId: string;
}

interface GetAllUnderlyingsResponseDto {
	underlyings: UnderlyingItemDto[];
}

type UnderlyingItemTypeDto = "Fund" | "Stock" | "Indice";
interface UnderlyingItemDto {
	id: number | undefined;
	underlyingType: UnderlyingItemTypeDto;
	ticker: string;
	isin: string;
	name: string;
	commercialName: string;
	globalDescription: string;
	lastStrike: number | undefined;
	// Indice
	legend: string;
	launch: string;
	longName: string;
	globalDisclaimer: string;
	adjustedName: string;
	decrement: string;
	decrementComparison: string;
	licensorWebsite: string;
	externalSource: string;
	// stock
	creation: string;
	secteur: string;
	notationSNP: string;
	notationMoodys: string;
	chiffreDaffaires: string;
	resultatNet: string;
	endettementNet: string;
	capitauxPropres: string;
	siteInternet: string;
	// fonds
	inconvenients: string;
	risks: string;
	fundComparison: string;
}

const noCacheDefaultHeaders: AxiosRequestConfig["headers"] = {
	"Cache-Control": "no-cache",
};

const DEFAULT_HEADERS = { "Content-Type": "application/json; charset=utf-8" };

export interface BaseApiError {
	errorMessage: string;
}

function getData<T>(x: AxiosResponse<T>) {
	return x.data;
}

export type GetRolesResponse = {
	roles: string[];
	optionalRoles: string[];
};

export function getRoles() {
	const token = getAuthorizationHeader() as string;
	return (api as AxiosInstance)
		.get<GetRolesResponse>("/user-roles", { headers: { ...DEFAULT_HEADERS, authorization: token } })
		.then(getData);
}

let sgwtConnect: SGWTConnectCore | undefined;
function getAuthorizationHeader() {
	if (sgwtConnect) {
		return sgwtConnect.getAuthorizationHeader();
	}
	const $widget = document.querySelector("sgwt-connect");
	if ($widget) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		sgwtConnect = ($widget as any).sgwtConnect as SGWTConnectCore | undefined;
	}
	return sgwtConnect?.getAuthorizationHeader();
}

let api: AxiosInstance | undefined;

export function initApi(baseURL: string) {
	api = axios.create({
		baseURL,
	});
}

export class BrochureGeneratorApi {
	loadReports(impersonatedUserEmail: string | undefined) {
		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const defaultHeader = { ...DEFAULT_HEADERS, authorization: token };
		const headers = isEmpty(impersonatedUserEmail)
			? { ...defaultHeader }
			: { "Impersonated-User-Email": impersonatedUserEmail, ...defaultHeader };
		const response = (api as AxiosInstance)
			.get<GetAllUserReportResponseDto>(getAllReportsEndpoint, {
				headers,
				cancelToken: source.token,
			})
			.then((x) => baseReportMapper.mapDtosToEntities(x.data.reports));

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(response as any)[CANCEL] = () => source.cancel();

		return response;
	}

	public initReportFromXOneId(
		xoneId: string,
		reportType: ReportType,
	): Promise<
		| TarnReportModel
		| AthenaReportModel
		| TauxFixeReportModel
		| PhoenixReportModel
		| PhoenixBearishReportModel
		| undefined
	> {
		const params: InitReportFromXOneRequestParams = { referenceId: xoneId, reportType };
		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const headers = { ...DEFAULT_HEADERS, authorization: token };
		const queryString = stringify({ ...params });
		const response = (api as AxiosInstance)
			.get<
				| TarnReportModelDto
				| AthenaReportModelDto
				| TauxFixeReportModelDto
				| PhoenixReportModelDto
				| PhoenixBearishReportModelDto
			>(`${getInitFromXOneIdEndpoint}?${queryString}`, {
				headers,
				cancelToken: source.token,
			})
			.then((r) => {
				const data = r.data;
				if (data && data.type === ReportType.Athena) {
					return athenaReportModelMapper.mapDtoToEntity(data);
				}
				if (data && data.type === ReportType.Tarn) {
					return tarnReportModelMapper.mapDtoToEntity(data);
				}
				if (data && data.type === ReportType.TauxFixe) {
					return tauxFixeReportModelMapper.mapDtoToEntity(data);
				}
				if (data && data.type === ReportType.Phoenix) {
					return phoenixReportModelMapper.mapDtoToEntity(data);
				}
				if (data && data.type === ReportType.PhoenixBearish) {
					return phoenixBearishReportModelMapper.mapDtoToEntity(data);
				}

				return undefined;
			});

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(response as any)[CANCEL] = () => source.cancel();

		return response;
	}

	public createAthenaReport(report: AthenaReportModelDto): Promise<ReportInfoItem> {
		const queryParams = { tradeReference: report.tradeReference };
		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const headers = { ...DEFAULT_HEADERS, authorization: token };
		const queryString = stringify({ ...queryParams });

		const response = (api as AxiosInstance)
			.post<ReportInfoItem>(`${createAthenaReportEndpoint}?${queryString}`, report.reportConfiguration, {
				headers,
				cancelToken: source.token,
			})
			.then((x) => x.data);

		(response as any)[CANCEL] = () => source.cancel();

		return response;
	}

	public createTarnReport(report: TarnReportModelDto): Promise<ReportInfoItem> {
		const queryParams = { tradeReference: report.tradeReference };
		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const headers = { ...DEFAULT_HEADERS, authorization: token };
		const queryString = stringify({ ...queryParams });

		const response = (api as AxiosInstance)
			.post<ReportInfoItem>(`${createTarnReportEndpoint}?${queryString}`, report.reportConfiguration, {
				headers,
				cancelToken: source.token,
			})
			.then((x) => x.data);

		(response as any)[CANCEL] = () => source.cancel();

		return response;
	}

	public createTauxFixeReport(report: TauxFixeReportModelDto): Promise<ReportInfoItem> {
		const queryParams = { tradeReference: report.tradeReference };
		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const headers = { ...DEFAULT_HEADERS, authorization: token };
		const queryString = stringify({ ...queryParams });

		const response = (api as AxiosInstance)
			.post<ReportInfoItem>(`${createTauxFixeReportEndpoint}?${queryString}`, report.reportConfiguration, {
				headers,
				cancelToken: source.token,
			})
			.then((x) => x.data);

		(response as any)[CANCEL] = () => source.cancel();

		return response;
	}

	public createPhoenixReport(report: PhoenixReportModelDto): Promise<ReportInfoItem> {
		const queryParams = { tradeReference: report.tradeReference };
		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const headers = { ...DEFAULT_HEADERS, authorization: token };
		const queryString = stringify({ ...queryParams });

		const response = (api as AxiosInstance)
			.post<ReportInfoItem>(`${createPhoenixReportEndpoint}?${queryString}`, report.reportConfiguration, {
				headers,
				cancelToken: source.token,
			})
			.then((x) => x.data);

		(response as any)[CANCEL] = () => source.cancel();

		return response;
	}

	public createPhoenixBearishReport(report: PhoenixBearishReportModelDto): Promise<ReportInfoItem> {
		const queryParams = { tradeReference: report.tradeReference };
		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const headers = { ...DEFAULT_HEADERS, authorization: token };
		const queryString = stringify({ ...queryParams });

		const response = (api as AxiosInstance)
			.post<ReportInfoItem>(`${createPhoenixBearishReportEndpoint}?${queryString}`, report.reportConfiguration, {
				headers,
				cancelToken: source.token,
			})
			.then((x) => x.data);

		(response as any)[CANCEL] = () => source.cancel();

		return response;
	}

	public getReportStatus(id: Guid): Promise<ReportStatusDto> {
		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const headers = { ...DEFAULT_HEADERS, authorization: token };

		const response = (api as AxiosInstance)
			.get<ReportStatusDto>(getReportStatusEndpoint(id), {
				headers,
				cancelToken: source.token,
			})
			.then((r) => r.data);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(response as any)[CANCEL] = () => source.cancel();

		return response;
	}

	public getReportConfiguration(
		id: Guid,
		impersonatedUserEmail: string | undefined,
	): Promise<
		| TarnReportModel
		| AthenaReportModel
		| TauxFixeReportModel
		| PhoenixReportModel
		| PhoenixBearishReportModel
		| undefined
	> {
		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const defaultHeader = { ...DEFAULT_HEADERS, authorization: token };
		const headers = isEmpty(impersonatedUserEmail)
			? { ...defaultHeader }
			: { "Impersonated-User-Email": impersonatedUserEmail, ...defaultHeader };

		const response = (api as AxiosInstance)
			.get<
				| TarnReportModelDto
				| AthenaReportModelDto
				| TauxFixeReportModelDto
				| PhoenixReportModelDto
				| PhoenixBearishReportModelDto
			>(getReportConfigurationEndpoint(id), {
				headers,
				cancelToken: source.token,
			})
			.then((r) => {
				const data = r.data;
				if (data && data.type === ReportType.Athena) {
					return athenaReportModelMapper.mapDtoToEntity(data);
				}
				if (data && data.type === ReportType.Tarn) {
					return tarnReportModelMapper.mapDtoToEntity(data);
				}
				if (data && data.type === ReportType.TauxFixe) {
					return tauxFixeReportModelMapper.mapDtoToEntity(data);
				}
				if (data && data.type === ReportType.Phoenix) {
					return phoenixReportModelMapper.mapDtoToEntity(data);
				}
				if (data && data.type === ReportType.PhoenixBearish) {
					return phoenixBearishReportModelMapper.mapDtoToEntity(data);
				}
				return undefined;
			});

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(response as any)[CANCEL] = () => source.cancel();

		return response;
	}

	public getAthenaAvailableUnderlyings(): Promise<AthenaUnderlyingId[]> {
		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const headers = { ...DEFAULT_HEADERS, authorization: token, ...noCacheDefaultHeaders };

		const response = (api as AxiosInstance)
			.get<GetAllAvailableAthenaUnderlyingsResponseDto>(`${getAthenaUnderlyingsEndpoint}`, {
				headers,
				cancelToken: source.token,
			})
			.then((r) => r.data.underlyings.map(athenaUnderlyingIdMapper.mapDtoToEntity));

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(response as any)[CANCEL] = () => source.cancel();

		return response;
	}

	public deleteReport(id: Guid): Promise<boolean> {
		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const headers = { ...DEFAULT_HEADERS, authorization: token };

		const response = (api as AxiosInstance)
			.delete(getDeleteReportEndpoint(id), {
				headers,
				cancelToken: source.token,
			})
			.then((_) => true)
			.catch((_) => false);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(response as any)[CANCEL] = () => source.cancel();

		return response;
	}

	public downloadReport(
		id: Guid,
		type: ReportDocumentType,
		impersonatedUserEmail: string | undefined,
	): Promise<BlobPart> {
		const source = axios.CancelToken.source();
		const queryStringData = {
			documentType: reportDocumentTypeMapper.mapEntityToDto(type),
		};
		const token = getAuthorizationHeader() as string;
		const headers = isEmpty(impersonatedUserEmail)
			? { ...DEFAULT_HEADERS, authorization: token }
			: { "Impersonated-User-Email": impersonatedUserEmail, ...DEFAULT_HEADERS, authorization: token };

		const response = (api as AxiosInstance)
			.get<BlobPart>(downloadReportEndpoint(id), {
				headers,
				cancelToken: source.token,
				responseType: "blob",
				params: queryStringData,
			})
			.then((r) => r.data);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(response as any)[CANCEL] = () => source.cancel();

		return response;
	}

	public getImages(): Promise<PictureInfo[]> {
		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const headers = { ...DEFAULT_HEADERS, authorization: token };

		const response = (api as AxiosInstance)
			.get<GetAllPicturesResponseDto>(getAllImageEndpoint, {
				headers,
				cancelToken: source.token,
			})
			.then((r) =>
				r.data.pictures.map((rawData) => {
					const item: PictureInfo = {
						id: rawData.id,
						name: rawData.name,
						imageUrl: undefined,
					};
					return item;
				}),
			);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(response as any)[CANCEL] = () => source.cancel();

		return response;
	}

	public getImageById(id: string): Promise<BlobPart> {
		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const headers = { ...DEFAULT_HEADERS, authorization: token };

		const response = (api as AxiosInstance)
			.get<BlobPart>(getImageEndpoint(id), {
				headers,
				cancelToken: source.token,
				responseType: "blob",
			})
			.then((r) => r.data);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(response as any)[CANCEL] = () => source.cancel();

		return response;
	}

	public getDisclaimers(): Promise<GetAllDisclaimersResponseDto> {
		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const headers = { ...DEFAULT_HEADERS, authorization: token, ...noCacheDefaultHeaders };

		const response = (api as AxiosInstance)
			.get<GetAllDisclaimersResponseDto>(getAllDisclaimersEndpoint, {
				headers,
				cancelToken: source.token,
			})
			.then((r) => r.data);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(response as any)[CANCEL] = () => source.cancel();

		return response;
	}

	public upsertDisclaimer = (disclaimer: DisclaimerItem): Promise<boolean | BaseApiError> => {
		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const headers = { ...DEFAULT_HEADERS, authorization: token };

		if (disclaimer.id) {
			const url = `${getAllDisclaimersEndpoint}/${disclaimer.id}`;
			const updateContentDto: DisclaimerItemDto = {
				content: disclaimer.content,
				description: disclaimer.description,
				disclaimerId: disclaimer.disclaimerId,
			};

			const response = (api as AxiosInstance)
				.patch<DisclaimerItemDto>(url, updateContentDto, {
					headers,
					cancelToken: source.token,
				})
				.then(() => true)
				.catch((reason: AxiosError<{ detail?: string }>) => {
					if (reason.response?.data?.detail) {
						const error: BaseApiError = {
							errorMessage: reason.response?.data?.detail,
						};
						return error;
					}

					return false;
				});

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(response as any)[CANCEL] = () => source.cancel();
			return response;
		} else {
			const url = `${getAllDisclaimersEndpoint}`;
			const newDisclaimerDto: DisclaimerItemDto = {
				content: disclaimer.content,
				description: disclaimer.description,
				disclaimerId: disclaimer.disclaimerId,
			};

			const response = (api as AxiosInstance)
				.post<DisclaimerItemDto>(url, newDisclaimerDto, {
					headers,
					cancelToken: source.token,
				})
				.then(() => true)
				.catch((reason: AxiosError<{ detail?: string }>) => {
					if (reason.response?.data?.detail) {
						const error: BaseApiError = {
							errorMessage: reason.response?.data?.detail,
						};
						return error;
					}

					return false;
				});

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(response as any)[CANCEL] = () => source.cancel();
			return response;
		}
	};

	public getAllUnderlyings(): Promise<GetAllUnderlyingsResponseDto> {
		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const headers = { ...DEFAULT_HEADERS, authorization: token, ...noCacheDefaultHeaders };

		const response = (api as AxiosInstance)
			.get<GetAllUnderlyingsResponseDto>(getAllUnderlyingsEndpoint, {
				headers,
				cancelToken: source.token,
			})
			.then((r) => r.data);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(response as any)[CANCEL] = () => source.cancel();

		return response;
	}

	public upsertUnderlying = (underlying: UnderlyingItem): Promise<boolean | BaseApiError> => {
		const underlyingDto: UnderlyingItemDto = {
			id: underlying.id,
			underlyingType: underlying.underlyingType,
			ticker: underlying.ticker,
			isin: underlying.isin,
			name: underlying.name,
			commercialName: underlying.commercialName,
			globalDescription: underlying.globalDescription,
			lastStrike: underlying.lastStrike,
			legend: underlying.legend,
			launch: underlying.launch,
			longName: underlying.longName,
			globalDisclaimer: underlying.globalDisclaimer,
			adjustedName: underlying.adjustedName,
			decrement: underlying.decrement,
			decrementComparison: underlying.decrementComparison,
			licensorWebsite: underlying.licensorWebsite,
			externalSource: underlying.externalSource,
			creation: underlying.creation,
			secteur: underlying.secteur,
			notationSNP: underlying.notationSNP,
			notationMoodys: underlying.notationMoodys,
			chiffreDaffaires: underlying.chiffreDaffaires,
			resultatNet: underlying.resultatNet,
			endettementNet: underlying.endettementNet,
			capitauxPropres: underlying.capitauxPropres,
			siteInternet: underlying.siteInternet,
			inconvenients: underlying.inconvenients,
			risks: underlying.risks,
			fundComparison: underlying.fundComparison,
		};

		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const headers = { ...DEFAULT_HEADERS, authorization: token };

		if (underlying.id) {
			// If we have an id, we are doing an update
			const url = `${getAllUnderlyingsEndpoint}/${underlying.ticker}`;

			const response = (api as AxiosInstance)
				.patch<UnderlyingItemDto>(url, underlyingDto, {
					headers,
					cancelToken: source.token,
				})
				.then(() => true)
				.catch((reason: AxiosError<{ detail?: string }>) => {
					if (reason.response?.data?.detail) {
						const error: BaseApiError = {
							errorMessage: reason.response?.data?.detail,
						};
						return error;
					}

					return false;
				});

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(response as any)[CANCEL] = () => source.cancel();
			return response;
		} else {
			const url = `${getAllUnderlyingsEndpoint}`;

			const response = (api as AxiosInstance)
				.post<DisclaimerItemDto>(
					url,
					{
						...underlying,
						id: undefined,
					},
					{
						headers,
						cancelToken: source.token,
					},
				)
				.then(() => true)
				.catch((reason: AxiosError<{ detail?: string }>) => {
					if (reason.response?.data?.detail) {
						const error: BaseApiError = {
							errorMessage: reason.response?.data?.detail,
						};
						return error;
					}

					return false;
				});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(response as any)[CANCEL] = () => source.cancel();
			return response;
		}
	};

	public deleteUnderlying(ticker: string): Promise<boolean> {
		const source = axios.CancelToken.source();
		const token = getAuthorizationHeader() as string;
		const headers = { ...DEFAULT_HEADERS, authorization: token };

		const response = (api as AxiosInstance)
			.delete(`${getAllUnderlyingsEndpoint}/${ticker}`, {
				headers,
				cancelToken: source.token,
			})
			.then((_) => true)
			.catch((_) => false);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(response as any)[CANCEL] = () => source.cancel();

		return response;
	}
}
