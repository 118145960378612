import { ReportType } from "Domain/report";
import { PhoenixReportConfiguration, PhoenixReportModel } from "Domain/report.phoenix";
import { PhoenixReportConfigurationDto, PhoenixReportModelDto } from "Domain/reportDto.phoenix";
import { PhoenixTransversalConfigurationMapper } from "./phoenixTransversalConfigurationMapper";

function mapEntityToDto(entity: PhoenixReportConfiguration, tradeReference: string | undefined): PhoenixReportModelDto {
	const configdto: PhoenixReportConfigurationDto = {
		transversalConfiguration: PhoenixTransversalConfigurationMapper.mapEntityToDto(entity.transversalConfiguration),
	};
	const dto: PhoenixReportModelDto = {
		reportConfiguration: configdto,
		type: ReportType.Phoenix,
		tradeReference,
	};
	return dto;
}
function mapDtoToEntity(dto: PhoenixReportModelDto): PhoenixReportModel {
	const reportConfig: PhoenixReportConfiguration = {
		transversalConfiguration: PhoenixTransversalConfigurationMapper.mapDtoToEntity(
			dto.reportConfiguration.transversalConfiguration,
		),
	};
	const model: PhoenixReportModel = {
		type: ReportType.Phoenix,
		configuration: reportConfig,
		tradeReference: dto.tradeReference,
	};
	return model;
}

export const phoenixReportModelMapper = {
	mapDtoToEntity,
	mapEntityToDto,
};
