import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { LoadingState } from "Models/LoadingState";
import { Guid, PictureInfo, ReportDocumentType, ReportInfoItem, ReportStatus, ReportType } from "Domain/report";
import { UserInfo } from "Models/userInfo";
import { AppTheme } from "Utilities/theme";
import { getImageEndpoint } from "Api/brochureUrlEndpoints";
import { DeployedEnvironment, getCurrentEnvironment } from "@analytics-web-core/core";
import { SGConnectConfig } from "SGConnectConfig";
import AthenaSlice from "state/Reports/Athena/Athena.state";
import TarnSlice from "state/Reports/Tarn/tarn.state";
import TauxFixeSlice from "state/Reports/TauxFixe/tauxFixe.state";
import PhoenixBearishSlice from "state/Reports/PhoenixBearish/PhoenixBearish.state";
import PhoenixSlice from "state/Reports/phoenix/Phoenix.state";

export type HomePageState = {
	showNewReportsModal: boolean;
	reportsLoadingState: LoadingState;
	xOneLoadingState: LoadingState;
	xOneErrorCode: number | undefined;
	reports: ReportInfoItem[];
	pictures: PictureInfo[];
	impersonatedUserEmail: string | undefined;
	impersonatedUserId: string | undefined;
	currentTheme: AppTheme;
	userPermissions: string[];
};

const defaultTheme: AppTheme =
	(window as any).SGWTWidgetConfiguration?.context?.getSgDesignSystemTheme() ?? AppTheme.Standard;

const defaultHomePageState: HomePageState = {
	showNewReportsModal: false,
	reportsLoadingState: LoadingState.Idle,
	xOneLoadingState: LoadingState.Idle,
	xOneErrorCode: undefined,
	reports: [],
	pictures: [],
	impersonatedUserEmail: undefined,
	impersonatedUserId: undefined,
	currentTheme: defaultTheme,
	userPermissions: [],
};

const HomePageSlice = createSlice({
	initialState: defaultHomePageState,
	name: "homepage",
	reducers: {
		loadReports: (state) => {
			state.reportsLoadingState = LoadingState.Pending;
		},
		setReports: (state, action: PayloadAction<{ reports: ReportInfoItem[] }>) => {
			state.reports = action.payload.reports;
			state.reportsLoadingState = LoadingState.Resolved;
		},
		setReportStatus: (state, action: PayloadAction<{ report: ReportInfoItem; status: ReportStatus }>) => {
			const { report, status } = action.payload;
			state.reports = state.reports.map((r) => {
				if (r.id === report.id) {
					return {
						...r,
						status,
					};
				}

				return r;
			});
		},
		setReportsError: (state) => {
			state.reportsLoadingState = LoadingState.Error;
		},
		showNewBrochureModal: (state) => {
			state.showNewReportsModal = true;
		},
		hideNewBrochureModal: (state) => {
			if (state.xOneLoadingState !== LoadingState.Pending) {
				state.showNewReportsModal = false;
				state.xOneLoadingState = LoadingState.Idle;
				state.xOneErrorCode = undefined;
			}
		},
		initReport: (state, __: PayloadAction<{ reportType: ReportType; ptpId: string | undefined }>) => {
			state.xOneLoadingState = LoadingState.Pending;
		},

		createReportFromXOneError: (state, action: PayloadAction<{ errorCode: number }>) => {
			const { errorCode } = action.payload;
			state.xOneLoadingState = LoadingState.Error;
			state.xOneErrorCode = errorCode;
		},
		duplicateBrochure: (state, __: PayloadAction<{ brochureId: Guid }>) => {},
		loadImages: (state) => {},
		loadImagesSuccess: (state: HomePageState, action: PayloadAction<{ pictures: PictureInfo[] }>) => {
			const currentEnv: DeployedEnvironment = getCurrentEnvironment() ?? "DEV";
			state.pictures = action.payload.pictures.map((picture) => {
				const pictureInfo: PictureInfo = {
					...picture,
					imageUrl: SGConnectConfig.envConfig[currentEnv].apiUrl + getImageEndpoint(picture.id),
				};
				return pictureInfo;
			});
		},
		setPictureLocalUrl: (state, action: PayloadAction<{ id: string; url: string }>) => {
			const index = state.pictures.findIndex((p) => p.id === action.payload.id);
			if (index > -1) {
				state.pictures[index].imageUrl = action.payload.url;
			}
		},
		deleteBrochure: (state, __: PayloadAction<{ brochureId: Guid }>) => {},
		deleteBrochureSuccess: (state) => {},
		downloadBrochure: (
			state,
			__: PayloadAction<{
				report: ReportInfoItem;
				documentType: ReportDocumentType;
				success?: () => void;
				error?: () => void;
			}>,
		) => {},
		setImpersonatedUserEmail: (
			state,
			action: PayloadAction<{ email: string | undefined; userId: string | undefined }>,
		) => {
			state.impersonatedUserEmail = !isEmpty(action.payload.email) ? action.payload.email : undefined;
			state.impersonatedUserId = !isEmpty(action.payload.userId) ? action.payload.userId : undefined;
		},
		setApplicationTheme: (state, action: PayloadAction<{ theme: AppTheme }>) => {
			state.currentTheme = action.payload.theme;
		},
		setUserAuthorization: (state, action: PayloadAction<{ authorization: UserInfo["user_authorization"] }>) => {
			state.userPermissions = (action.payload.authorization ?? []).flatMap((a) =>
				(a.permissions ?? []).map((p) => p.name!),
			);
		},
	},
	extraReducers(builder) {
		builder
			.addCase(AthenaSlice.actions.initReportSuccess, (state) => {
				state.xOneLoadingState = LoadingState.Resolved;
				state.xOneErrorCode = undefined;
				state.showNewReportsModal = false;
			})
			.addCase(TarnSlice.actions.initReportSuccess, (state) => {
				state.xOneLoadingState = LoadingState.Resolved;
				state.xOneErrorCode = undefined;
				state.showNewReportsModal = false;
			})
			.addCase(TauxFixeSlice.actions.initReportSuccess, (state) => {
				state.xOneLoadingState = LoadingState.Resolved;
				state.xOneErrorCode = undefined;
				state.showNewReportsModal = false;
			})
			.addCase(AthenaSlice.actions.initReportNotManaged, (state) => {
				state.xOneLoadingState = LoadingState.Resolved;
			})
			.addCase(PhoenixSlice.actions.initReportSuccess, (state) => {
				state.xOneLoadingState = LoadingState.Resolved;
				state.xOneErrorCode = undefined;
				state.showNewReportsModal = false;
			})
			.addCase(PhoenixBearishSlice.actions.initReportSuccess, (state) => {
				state.xOneLoadingState = LoadingState.Resolved;
				state.xOneErrorCode = undefined;
				state.showNewReportsModal = false;
			});
	},
});
export default HomePageSlice;
