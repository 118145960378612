import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PhoenixReportConfiguration } from "Domain/report.phoenix";
import { cloneDeep } from "lodash";
import * as immer from "immer";
import { Schema } from "yup";
import { validateModel } from "Helpers/validationHelpers";
import { LoadingState } from "Models/LoadingState";
import { FieldConfigurationFromModel } from "components/Shared/FormControls/FieldConfiguration";
import { getPhoenixFormFieldConfiguration } from "./getPhoenixFormFieldConfiguration";
import { ReportInfoItem } from "Domain/report";
import { getPhoenixValidationSchema } from "./getPhoenixValidationSchema";
import { createDefaultPhoenixReport } from "./createDefaultPhoenixReport";

interface EditReportConfigurationValuePayload<
	TReportConfigKey extends keyof PhoenixReportConfiguration,
	TFieldKey extends keyof PhoenixReportConfiguration[TReportConfigKey],
> {
	contextKey: TReportConfigKey;
	field: TFieldKey;
	value: PhoenixReportConfiguration[TReportConfigKey][TFieldKey];
}

export type PhoenixState = {
	formState: PhoenixFormState;
	initialReport: PhoenixReportConfiguration;
	currentReport: PhoenixReportConfiguration;
	brochureLoadingState: LoadingState;
	tradeReference: string | undefined;
};

export type PhoenixReportValidationSchema = Schema<PhoenixReportConfiguration>;

export type PhoenixFormState = {
	fieldConfiguration: FieldConfigurationFromModel<PhoenixReportConfiguration>;
	validationSchema: PhoenixReportValidationSchema;
	validationState: Record<string, string>;
};

const defaultState: PhoenixState = {
	formState: {
		fieldConfiguration: getPhoenixFormFieldConfiguration(),
		validationSchema: getPhoenixValidationSchema(),
		validationState: {},
	},
	initialReport: createDefaultPhoenixReport(),
	currentReport: createDefaultPhoenixReport(),
	brochureLoadingState: LoadingState.Idle,
	tradeReference: undefined,
};

export const editValueCreatorHelper = <
	TReportConfigKey extends keyof PhoenixReportConfiguration,
	TFieldKey extends keyof PhoenixReportConfiguration[TReportConfigKey],
>(
	key: TReportConfigKey,
	field: TFieldKey,
	value: PhoenixReportConfiguration[TReportConfigKey][TFieldKey],
) => {
	const payload: EditReportConfigurationValuePayload<TReportConfigKey, TFieldKey> = {
		contextKey: key,
		field,
		value,
	};
	return PhoenixSlice.actions.editValue(payload as any);
};

const PhoenixSlice = createSlice({
	initialState: defaultState,
	name: "phoenixReport",
	reducers: {
		editValue: <
			TReportConfigKey extends keyof PhoenixReportConfiguration,
			TFieldKey extends keyof PhoenixReportConfiguration[TReportConfigKey],
		>(
			state: immer.Draft<PhoenixState>,
			action: PayloadAction<EditReportConfigurationValuePayload<TReportConfigKey, TFieldKey>>,
		) => {
			const { contextKey, field, value } = action.payload;

			if (state.currentReport) {
				state.currentReport[contextKey][field] = value;
			}
		},
		initReport: (state) => {},
		initReportSuccess: (
			state,
			action: PayloadAction<{
				reportConfiguration: PhoenixReportConfiguration;
				tradeReference: string | undefined;
			}>,
		) => {
			state.initialReport = cloneDeep(action.payload.reportConfiguration);
			state.currentReport = cloneDeep(action.payload.reportConfiguration);
			state.formState.validationState =
				validateModel(
					state.currentReport,
					state.formState.validationSchema as any as Schema<PhoenixReportConfiguration>,
				) || {};
			state.tradeReference = action.payload.tradeReference;
		},
		generateBrochure: (state) => {
			const validationState = validateModel(
				state.currentReport,
				state.formState.validationSchema as any as Schema<PhoenixReportConfiguration>,
			);
			if (validationState) {
				state.formState.validationState = validationState;
			} else {
				state.formState.validationState = {};
				state.brochureLoadingState = LoadingState.Pending;
			}
		},
		generateBrochureSuccess: (state) => {
			state.brochureLoadingState = LoadingState.Resolved;
		},
		generateBrochureError: (state) => {
			state.brochureLoadingState = LoadingState.Error;
		},
		resetReport: (state) => {
			state.currentReport = cloneDeep(state.initialReport);
			state.formState.validationState = {};
		},
		downloadBrochure: (
			_state,
			_action: PayloadAction<{ report: ReportInfoItem; success?: () => void; error?: () => void }>,
		) => {},
	},
});

export default PhoenixSlice;
