import { FieldConfigurationFromModel } from "components/Shared/FormControls/FieldConfiguration";
import {
	commissionTypeItems,
	productClientItems,
	productCurrencyItems,
	productEligibilityItems,
	productLawItems,
} from "Domain/report";
import { PhoenixReportConfiguration } from "Domain/report.phoenix";

export function getPhoenixFormFieldConfiguration(): FieldConfigurationFromModel<PhoenixReportConfiguration> {
	const config: FieldConfigurationFromModel<PhoenixReportConfiguration> = {
		transversalConfiguration: {
			productName: { type: "Text", displayLabel: "Product Name *" },
			issuePrice: { type: "Percent", displayLabel: "Issue Price *" },
			priceRate: { type: "Percent", displayLabel: "Price Rate *" },
			productIssuer: { type: "Text", displayLabel: "Issuer *" },
			minimumInvestment: { type: "Number", displayLabel: "Minimum Investment *" },
			productGuarantor: { type: "Text", displayLabel: "Guarantor (optional)" },
			issueDate: { type: "Date", displayLabel: "Issue Date *" },
			productCurrency: { type: "List", displayLabel: "Currency *", datasource: productCurrencyItems },
			maturityDate: { type: "Date", displayLabel: "Maturity Date *" },
			productDenomination: { type: "Number", displayLabel: "Nominal Value *" },
			productDuration: { type: "Duration", displayLabel: "Product Duration *" },

			productCotation: { type: "Text", displayLabel: "Cotation *" },
			productLaw: { type: "List", displayLabel: "Product Law *", datasource: productLawItems },
			bidOffer: { type: "Percent", displayLabel: "Bid/Offer Spread *" },
			productEligibility: { type: "List", displayLabel: "Product Eligibility *", datasource: productEligibilityItems },
			commission: { type: "Percent", displayLabel: "Commission *" },
			commissionType: { type: "List", displayLabel: "Commission Type *", datasource: commissionTypeItems },
			marketingPeriod: { type: "DateRange", displayLabel: "Marketing Dates (optional)" },
			doubleValuation: { type: "Text", displayLabel: "Double Valuation *" },
			mtmPublication: { type: "Text", displayLabel: "Publication Of Liquidative Value *", multiline: false },
			isin: { type: "Text", displayLabel: "ISIN Code (optional)" },
			calculationAgent: { type: "Text", displayLabel: "Calculation *" },
			client: { type: "List", displayLabel: "Client *", datasource: productClientItems },
			initialValuationDate: { type: "Date", displayLabel: "Initial Valuation Date" },
			ftDate: { type: "Date", displayLabel: "FT Date (optional)" },
			hasMarketingPeriod: { type: "Checkbox", displayLabel: null, valueLabel: "Offer Period" },
			colorPreset: "Preset *",
		},
	};
	return config;
}
