import { BrochureGeneratorAppState } from "Domain/report";
import { LoadingState } from "Models/LoadingState";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReportFormSection } from "../ReportFormSection";
import { useAnalyticsMonitoring } from "@analytics-web-core/core";
import PhoenixSlice, { editValueCreatorHelper } from "state/Reports/phoenix/Phoenix.state";
import PhoenixFormControl from "./Form/PhoenixFormControl";
import GeneratePhoenixBrochureButton from "./GeneratePhoenixBrochureButton";
import { PictureSelector } from "components/Shared/PictureSelector/PictureSelector";
import { ColorSelector } from "components/Shared/ColorSelector/ColorSelector";
import { LiquidityValuesHelper } from "../Athena/AthenaForm";
import { cssHelper } from "components/Shared/cssHelper";
import { usePhoenixValidationStateForField } from "state/Reports/phoenix/usePhoenixValidationStateForField";

export const PhoenixReport: React.FC = () => {
	const PhoenixState = useSelector((a: BrochureGeneratorAppState) => a.phoenix);
	const dispatcher = useDispatch();
	const { brochureLoadingState, currentReport } = PhoenixState;
	const isLoading = brochureLoadingState === LoadingState.Pending;
	const pictureValidationState = usePhoenixValidationStateForField("transversalConfiguration", "pictureId");
	const colorValidationState = usePhoenixValidationStateForField("transversalConfiguration", "colorPreset");
	const analyticsMonitoring = useAnalyticsMonitoring();
	const { hasMarketingPeriod, productEligibility, pictureId, colorPreset } = currentReport.transversalConfiguration;

	const onReset = () => {
		dispatcher(PhoenixSlice.actions.resetReport());
		analyticsMonitoring.functional("Phoenix ", "Reset form");
	};

	return (
		<div className="d-flex flex-column h-100 overflow-hidden">
			<div className="p-3 d-flex align-items-center shadow border-bottom">
				<div className="display-4 flex-fill">Phoenix </div>
			</div>
			<div className="flex-fill position-relative bg-lvl3">
				{isLoading && <div className="prime-overlay"></div>}
				<div className="position-absolute w-100 h-100 px-3 py-4" style={{ overflowY: "auto" }}>
					<div className="row mb-4">
						<div className="col">
							<ReportFormSection title={"Product Information"} containerClassName="mb-1">
								<div className="row">
									<div className="col-6">
										<PhoenixFormControl contextKey="transversalConfiguration" field={"productName"} />
										<PhoenixFormControl contextKey="transversalConfiguration" field={"productIssuer"} />
										<PhoenixFormControl contextKey="transversalConfiguration" field={"productGuarantor"} />
										<PhoenixFormControl contextKey="transversalConfiguration" field={"productCurrency"} />
										<PhoenixFormControl contextKey="transversalConfiguration" field={"productDenomination"} />
									</div>
									<div className="col-6">
										<div className="row">
											<PhoenixFormControl contextKey="transversalConfiguration" field={"issuePrice"} />
											<PhoenixFormControl contextKey="transversalConfiguration" field={"priceRate"} />
										</div>
										<PhoenixFormControl contextKey="transversalConfiguration" field={"minimumInvestment"} />
										<PhoenixFormControl contextKey="transversalConfiguration" field={"issueDate"} />
										<PhoenixFormControl contextKey="transversalConfiguration" field={"maturityDate"} />
										<PhoenixFormControl contextKey="transversalConfiguration" field={"productDuration"} />
									</div>
								</div>
							</ReportFormSection>
						</div>
						<div className="col">
							<ReportFormSection title={"Schedule"} containerClassName="mb-4"></ReportFormSection>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<ReportFormSection title={"PayOff Information"} containerClassName="mb-4"></ReportFormSection>
						</div>
						<div className="col">
							<ReportFormSection title={"Deal Information"} containerClassName="mb-4">
								<div className="row">
									<div className="col-4">
										<PhoenixFormControl contextKey="transversalConfiguration" field={"productCotation"} />
									</div>
									<div className="col-4">
										<PhoenixFormControl contextKey="transversalConfiguration" field={"productLaw"} />
									</div>
									<div className="col-4">
										<PhoenixFormControl contextKey="transversalConfiguration" field={"bidOffer"} />
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<PhoenixFormControl contextKey="transversalConfiguration" field={"productEligibility"} />
									</div>
								</div>
								<div className="row">
									<div className="col-4">
										<PhoenixFormControl contextKey="transversalConfiguration" field={"commission"} />
									</div>
									<div className="col-4">
										<PhoenixFormControl contextKey="transversalConfiguration" field={"commissionType"} />
									</div>
								</div>
								<div className="row">
									<div className="col-3">
										<PhoenixFormControl contextKey="transversalConfiguration" field="hasMarketingPeriod" />
									</div>
									<div className="col-5">
										<PhoenixFormControl
											contextKey="transversalConfiguration"
											field="marketingPeriod"
											fieldConfigOverrides={{ hidden: !hasMarketingPeriod }}
										/>
									</div>
									<div className="col-4">
										<PhoenixFormControl contextKey="transversalConfiguration" field="doubleValuation" />
									</div>
								</div>
								<div className="row">
									<PhoenixFormControl contextKey="transversalConfiguration" field={"mtmPublication"} />
									<div className={cssHelper.colSizeClassNames(4, "mb-3")} style={{ maxWidth: 500 }}>
										<div className="form-label">&nbsp;</div>
										<LiquidityValuesHelper
											onSelect={(val) =>
												dispatcher(editValueCreatorHelper("transversalConfiguration", "mtmPublication", val))
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-4">
										<PhoenixFormControl contextKey="transversalConfiguration" field={"isin"} />
									</div>
									<div className="col-4">
										<PhoenixFormControl contextKey="transversalConfiguration" field={"calculationAgent"} />
									</div>
									<div className="col-4">
										<PhoenixFormControl contextKey="transversalConfiguration" field={"client"} />
									</div>
								</div>
								<div className="row">
									<PhoenixFormControl contextKey="transversalConfiguration" field={"initialValuationDate"} size={4} />
									<PhoenixFormControl
										contextKey="transversalConfiguration"
										field="ftDate"
										size={{ defaultSize: 12, lg: 4 }}
										fieldConfigOverrides={{ hidden: productEligibility == null }}
									/>
								</div>
								<div className="row">
									<div className="col-6">
										<div className="form-label">Cover Picture</div>
										<PictureSelector
											errorMessage={pictureValidationState}
											selectedPictureId={pictureId}
											onChange={(id) => {
												dispatcher(editValueCreatorHelper("transversalConfiguration", "pictureId" as any, id));
											}}
										/>
									</div>
									<div className="col-6">
										<div className="form-label">Client Theme</div>
										<ColorSelector
											errorMessage={colorValidationState}
											headerTitle="Client Theme"
											selectedPreset={colorPreset}
											onChange={(preset) => {
												dispatcher(editValueCreatorHelper("transversalConfiguration", "colorPreset" as any, preset));
											}}
										/>
									</div>
								</div>
							</ReportFormSection>
						</div>
					</div>
				</div>
			</div>

			<div className="p-3 shadow border-top">
				<div className="container-fluid p-0">
					<div className="row">
						<div className="col-auto ms-auto">
							<button className="btn btn-xl btn-outline-secondary" disabled={isLoading} onClick={onReset}>
								Reset
							</button>
						</div>
						<div className="col-auto">
							<GeneratePhoenixBrochureButton />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
