import { combineReducers } from "@reduxjs/toolkit";
import { defaultConfigReducer, defaultConfigurationFactory } from "@analytics-web-core/core";
import _ from "lodash";
import { initApi } from "Api/brochureGeneratorApi";
import { SGConnectConfig } from "SGConnectConfig";
import HomePageSlice from "state/HomePage/HomePage.state";
import AthenaSlice from "state/Reports/Athena/Athena.state";
import TarnSlice from "state/Reports/Tarn/tarn.state";
import TauxFixeSlice from "state/Reports/TauxFixe/tauxFixe.state";
import ResourcesSlice from "state/Resources/Resources.state";
import { authorizationSlice } from "state/authorization/authorizationReducer";
import { BrochureGeneratorAppState } from "Domain/report";
import PhoenixBearishSlice from "state/Reports/PhoenixBearish/PhoenixBearish.state";
import PhoenixSlice from "state/Reports/phoenix/Phoenix.state";

const initialConfigState = defaultConfigurationFactory(SGConnectConfig);

initApi(initialConfigState.currentEnvConfig?.apiUrl as string);

export const reducer = combineReducers<BrochureGeneratorAppState>({
	config: defaultConfigReducer(initialConfigState),
	authorization: authorizationSlice.reducer,
	athena: AthenaSlice.reducer,
	tarn: TarnSlice.reducer,
	homepage: HomePageSlice.reducer,
	tauxFixe: TauxFixeSlice.reducer,
	phoenix: PhoenixSlice.reducer,
	phoenixBearish: PhoenixBearishSlice.reducer,
	resources: ResourcesSlice.reducer,
});

export type PartialRecord<K extends keyof any, T> = {
	[P in K]?: T;
};
