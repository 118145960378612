import {
	ColorPreset,
	DurationInfo,
	Guid,
	ProductClient,
	ProductCurrency,
	productCurrencyItems,
	ProductEligibility,
	productEligibilityItems,
	ProductLaw,
	productLawItems,
} from "Domain/report";
import { PhoenixReportConfiguration, PhoenixTransversalConfiguration } from "Domain/report.phoenix";
import * as yup from "yup";

export const transversalConfigurationSchema = (): yup.Schema<PhoenixTransversalConfiguration> =>
	yup.object({
		productName: yup.string().defined().min(1),
		issuePrice: yup.number().defined(),
		priceRate: yup.number().defined(),
		productIssuer: yup.string().defined().min(1),
		minimumInvestment: yup.number().defined(),
		productGuarantor: yup.string().optional(),
		issueDate: yup.date().defined(),
		productCurrency: yup
			.mixed<ProductCurrency>()
			.oneOf(Object.values(productCurrencyItems).map((e) => e.key))
			.defined(),
		maturityDate: yup.date().defined(),
		productDenomination: yup.number().defined(),
		productDuration: yup.mixed<DurationInfo>().defined(),

		hasMarketingPeriod: yup.bool().defined(),
		marketingPeriod: yup
			.object({
				startDate: yup.date().required(),
				endDate: yup.date().required(),
			})
			.when("hasMarketingPeriod", { is: true, then: (s) => s.required() }),

		productCotation: yup.string().defined().min(1),
		calculationAgent: yup.string().defined().min(1),
		isin: yup.string().optional(),
		mtmPublication: yup.string().required().min(1),
		productLaw: yup
			.mixed<ProductLaw>()
			.oneOf(Object.values(productLawItems).map((e) => e.key))
			.defined(),
		pictureId: yup.mixed<Guid>().defined(),
		commission: yup.number().defined(),
		commissionType: yup.string().required(),
		client: yup.mixed<ProductClient>().defined(),
		productEligibility: yup
			.mixed<ProductEligibility>()
			.required()
			.oneOf(Object.values(productEligibilityItems).map((e) => e.key)),
		initialValuationDate: yup.date().defined(),
		doubleValuation: yup.string().defined(),
		bidOffer: yup.number().defined(),
		colorPreset: yup.mixed<ColorPreset>().defined(),
	});

export function getPhoenixValidationSchema(): yup.Schema<PhoenixReportConfiguration> {
	const schema: yup.Schema<PhoenixReportConfiguration> = yup.object().shape({
		transversalConfiguration: transversalConfigurationSchema(),
	});

	return schema;
}
